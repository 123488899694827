import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Gap, Icon, Spacing, Text } from '@reservamos/elements';
import './CounterSeat.scss';

/**
 * It shows the selected seats
 * @param {*} param0 - Props
 * @param {node} param0.children - Children
 * @param {boolean} param0.hasPetFriendly - If selected seats includes a pet friendly seat
 * @returns - CounterSeat component.
 */
const CounterSeat = ({ children, hasPetFriendly }) => {
  const { t } = useTranslation('seats');

  return (
    <div className="counter-seat">
      <Spacing vertical size="S">
        <Gap alignItems="center">
          <Gap alignItems="flex-end">
            <Text size="L" weight="bold">
              {t('pet_counter.title')}
            </Text>
            <Icon type="emojiBusSide" />
          </Gap>

          {children}
        </Gap>

        {hasPetFriendly && (
          <Gap alignItems="center" columnGap="XXS">
            <Icon type="emojiPawsPet" size="S" />
            <Icon type="emojiPerson" size="S" />

            <Text size="S" mobileSize="XS" weight="semibold" color="grayMedium">
              {t('pet_counter.message')}
            </Text>
          </Gap>
        )}
      </Spacing>
    </div>
  );
};

CounterSeat.propTypes = {
  children: PropTypes.node.isRequired,
  hasPetFriendly: PropTypes.bool,
};

CounterSeat.defaultProps = {
  hasPetFriendly: false,
};

export default CounterSeat;
